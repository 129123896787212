/*Taken from https://www.w3schools.com/howto/howto_js_autocomplete.asp */

.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
}

.autocompleteInput {
    border: 1px solid transparent;
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
}

.autocompleteInput[type=text] {
    background-color: #fff;
    width: 100%;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}