.dynamic-table{

}

.dynamic-table-row__overlay{

}

/* ROW CLASSES */

.dynamic-table-row{
    position: relative;
}

.dynamic-table-row--inactive > td{
    background: #F08080;
    color: #fff;
}

.dynamic-table-row--inactive > td > .dynamic-table-col__link{
    color: #fff;
}

.dynamic-table-row--inactive > td > .dynamic-table-col__link:hover{
    color: #fff;
}

.dynamic-table-row--updating > td{
    background-color: rgba(0,0,0,.6);
    color: #fff;
}


/* COL CLASSES */

.dynamic-table-col{
    
}



.dynamic-table-col__link{
    color: rgb(102, 102, 102);
    display: block;
}

.dynamic-table-col__link:hover{
    color: rgb(102, 102, 102);
    text-decoration: underline;
}

.dynamic-table-col::first-letter{
    text-transform: capitalize;
}

.dynamic-table-col--sortable{
    position: relative;
    cursor: pointer;
}

.dynamic-table-col--sortable:hover{
    background: #ADD8E6;
}


.dynamic-table-col--sm{
    width: 1px;
    text-align: center;
    min-width: min-content;
}


.dynamic-table-col--active{
    background: #ADD8E6;
}

.dynamic-table-col__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.dynamic-table-col__container::first-letter{
    text-transform: capitalize;
}

.dynamic-table-col__sort-container{
   display: flex;
}

.dynamic-table-col__sort-btn{
    margin: 0 5px;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 16px;
}

.dynamic-table-col__sort-btn--active{
    color: #0f0;
}

/* PAGINATION CLASSES */

.dynamic-table__pagination{
    display: flex;

    margin-top: -10px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}

.dynamic-table__pagination-btn{
    border: 1px solid #333;
    padding: 5px 10px;
    font-size: 14px;
    
}

.dynamic-table__pagination-btn--current{
    background: #333;
    color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

@media only screen and (max-width: 800px){
    .dynamic-table-col{    
        max-width: 200px;
        overflow: hidden;   
        text-overflow: ellipsis;
    }
}








