

.table-responsive .user-contribution > td {
    vertical-align: middle;
}

.table-responsive .user-contribution > th {
    vertical-align: middle;
}



.user-contribution--inactive > td{
    background: #F08080;
    color: #fff;
}

.user-contribution--inactive:nth-of-type(even) > td{
    background: #FA8072;
    color: #fff;
}

.user-contribution--author{

}



.user-contribution__text{
    flex: 1;
}

.user-contribution__type{
    background: #666;
    color: #fff;
    text-align: center;
}

.user-contribution--book .user-contribution__type{
    background: #808000;
}

.user-contribution--author .user-contribution__type{
    background: #800080;
}




.user-contribution--author > .user-contribution__type{
 
}

