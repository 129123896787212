.picture-edit{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

}

.upload-field{
    position: relative;
}



.upload-field__input{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    max-width: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

/* label styles */

.upload-field__label{
    padding: 1rem;
    cursor: pointer;
    border: 2px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    transition: all .5s;   
}

.upload-field--dragged .upload-field__label{
    border-color: blue;
    background: #dbdbdb;
}

.upload-field__label:hover{
    border: 2px solid blue;
    background: #dbdbdb;
}

.upload-field__remove-btn:hover + .upload-field__label{
    border: 2px solid #b33A3a;
    background:#dbdbdb;
}

.upload-field__label::before{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    background-color: rgba(0,0,0, .5);
    z-index: 1;
    opacity: 0;
    transition: all .5s;
}



.upload-field--non-empty .upload-field__label:hover::before{
    opacity: .8;
    content: "edit picture";
 }

 .upload-field--dragged.upload-field--non-empty .upload-field__label::before{
     opacity: 1;
     content: "edit picture"
 }

 
 .upload-field--non-empty .upload-field__remove-btn:hover + .upload-field__label::before{
    opacity: .8;
    content: "remove picture";
 }

/* remove btn styles */

.upload-field__remove-btn{
    position: absolute;
    top: 0;
    right: 0;
    padding: .6rem 1rem;
    z-index: 2;
    background: #666;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: .5s;
}

.upload-field__remove-btn i{
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}

.upload-field:hover .upload-field__remove-btn{
    background-color: blue;
}

.upload-field .upload-field__remove-btn:hover {
    background: #b33A3a;
}

.upload-field--dragged.upload-field--non-empty .upload-field__remove-btn{
    background-color: blue;
}


/* cta styles */

.upload-field__cta{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-field__cta-icon{
    margin-bottom: .5rem;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-field__cta-icon i{
    font-size: 50px;
    transition: all .2s;
}

.upload-field--dragged .upload-field__cta-icon i{
    font-size: 30px;
}

.upload-field__cta .btn{
    margin: 1rem 0;
}

/* display image styles */
.upload-field__image{
    display: inline;
    margin: 0 auto .5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;    
}


