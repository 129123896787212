.book-edit-header{
    display: flex;
    justify-content: space-between;
}

.book-edit-header__heading{
    font-size: 1.8em;
}

@media screen and (max-width: 600px) {
    .book-edit-header {
      flex-direction: column;
    }
  }