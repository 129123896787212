#loadCaption {
    pointer-events: none;
    font-size: 30px;
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translate(-50%, 0);
}

#loadSpinner {
    position: absolute;
    margin-left: -70px;
    margin-top: -20px;
    font-size: 200%;
    animation: LoadSpinnerAnimation infinite 2s linear;
}

@keyframes LoadSpinnerAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#overlay {
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 200;
    cursor: progress;
}

#clearFloat {
    clear: both;
}

#loadIndicator {
    position: relative;
}