.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover, .link-button:focus {
  text-decoration: none;
}

/* 
  makes the content always fill at least the whoole page
*/

#root{
  height: 100%;
}

.main-layout{
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-layout__main{
  flex-grow: 1;
}


.row-eq-height-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}