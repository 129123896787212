

.panel-collapsible .panel-heading{
    padding: 0;
}

.panel-collapsible .panel-heading a{
    display: flex;
    align-items: center;
    color: inherit;
    padding: 10px 30px;
}

.panel-collapsible .panel-heading a:before{
    font-family: FontAwesome;
    content:"\f068";
    padding-right: 5px;  
}

.panel-collapsible .panel-heading a.collapsed:before{
    content:"\f067";
}

