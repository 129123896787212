.select-multiple{
    display: flex;
    position: relative;    
    flex-direction: row;
    width: 100%;
}

.select-multiple__input{
    flex: 1;
}

.select-multiple__input:disabled{
    background: #fff;
}

.select-multiple__input--hide-placeholder::placeholder{
    opacity: 0;
}

.select-multiple__input--hide-placeholder::-ms-input-placeholder{
    opacity: 0;
}

.select-multiple__items{
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 130px;
    overflow: hidden;
}

.select-multiple__select{
    background: #eee;
    position: absolute;
    right: 0;
    width: fit-content;
    max-width: 140px;
    z-index: 1;
    cursor: pointer;
}

.select-multiple__hidden-input{
    display: none;
}

.select-item{
    background: #eee; 
    border: 1px solid #dbdbdb;
    padding: 2px 10px;
    border-radius: 10rem;
    display: flex;
    margin-right: 10px;
}

.select-item__close{
    margin-left: 5px;
    font-size: 16px;
    outline: none;
}

@media only screen and (max-width: 600px){
    .select-multiple__items{
        overflow-x: scroll;
    }

    .select-multiple__select{
        width: fit-content;

    }
}