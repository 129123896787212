

/* header navigation styles */

.main-navbar .navbar-collapse{

    overflow-y: hidden;
}

.main-navbar__navigation{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.main-navbar__links{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    color: #666;
    list-style: none;
    font-size: 14px;
}

.main-navbar__links--right{
    margin-left: auto;
}

.main-navbar__links li{
    font-size: inherit;
    color: inherit;
    list-style: none;
    padding: 0 15px
}


.main-navbar__links li:first-child{
    padding-left: 0;
}

.main-navbar__links li + li{
    border-left: 1px solid #dbdbdb
}


.main-navbar__links li a{
    color: inherit;
    display: block;
    
}

.main-navbar__links li a i{
    padding-right: 10px;
}

.main-navbar__links li a em{
    font-style: normal;
}

.main-navbar__toggle{
    border: 1px solid #666;
    float: none;
}

.main-navbar__toggle .icon-bar{
    background-color: #666 !important;
}

/* header nav dropown menu styles */

.main-navbar__links .dropdown-menu li{
    border-left: none;
}

.main-navbar__links .dropdown-menu li:first-child{
   padding-left: 15px;
}

@media(max-width: 991px){
    .main-navbar__navigation{
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        padding: 5px 0;
    }

    .main-navbar__links{
        flex-direction: column;
        align-items: stretch;
        margin: 0;
        padding: 0;
    }

    .main-navbar__links li{
        padding: 0;
    }

    .main-navbar__links li:not(:last-child){
        border-bottom: 1px solid #dbdbdb;
    }

    .main-navbar__links li + li{
        border-left: none;
    }

    .main-navbar__links:not(:last-child){
        border-bottom: 1px solid #dbdbdb;
    }

    .main-navbar__links li a{
        padding: 5px 0px;
    }

    .main-navbar__links li a:hover{
        background: rgba(230,240,230, 0.5);
    }

    .main-navbar__links .dropdown-menu{
        position: relative;
        top: 0;
        width: 100%;
        border: none;
        box-shadow: none;
        padding-left: 15px;

    }

    .main-navbar__links .dropdown-menu li:first-child{
        padding: 0;
    }

    .main-navbar__links .dropdown-menu li a{
        padding: 10px 0;
    }

    .main-navbar__links .dropdown-menu .divider{
        display: none;
    }
   
}

