.ImprintTable {
    margin: 30px;
    border-spacing: 0 15px;
    border-collapse:separate; 
}

.ImprintTable tr td:first-child {
    vertical-align: top;
    font-weight: bold;
    padding-right: 20px;
}