.picture-editor{
    display: flex;
    flex-wrap: wrap;  
    justify-content: flex-start;
    position: relative;
    align-content: center;
}

.picture-editor__wrapper{
    max-height: 830px;
    overflow-y: scroll;
    position: relative;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #efefef;
    box-shadow: inset 0 .5rem 1rem rgba(0,0,0, .15);
    padding: 10px;
    margin-bottom: 20px;
}

.picture-editor__add-new-btn{
    border: 1px solid #ddd;

    height: 100%;
    width: 100%;
    background: #fff;
    transition: all .2s;
    box-shadow: 0 .5rem 1rem rgba(0,0,0, .15);
   

}

.picture-editor__btn-wrapper{
    padding: 10px;
    min-height: 300px;
    flex: 1;
    width: 25%;
    max-width: 25%;

}


.picture-editor__picture-header{
    background: #333;
    padding: 10px;
    position: relative;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    justify-content: flex-end;   
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0,0,0, .15);
    width: 100%;

}

.picture-editor__picture-header > i{
    color: #fff;
    transition: all .2s;
    padding: 3px;
    border-radius: 3px;
}

.picture-editor__picture-header > i:hover{
    background-color: #666;
}

.picture-editor__picture-body{


 
    padding: 10px;

    background: #fff;
    transition: all .2s;
    box-shadow: 0 .5rem 1rem rgba(0,0,0, .15);
    max-height: 100%;
}

.picture-editor__picture{
    padding: 10px;
    flex-grow: 0;
    height: 100%;
    flex-grow: 0;
    width: 25%;  
}

.picture-editor__picture--main .picture-editor__picture-header{
    background: #808000;
}

.picture-editor__main-picture{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 14px;
}


.picture-editor-card__wrapper{
    padding: 10px;
    min-height: 300px;

    flex: 0 0 25%;
    max-width: 25%;
}

.picture-editor-card{
    width: 100%;
    height: 100%;
    background: #fff; 
    box-shadow: 0 .5rem 1rem rgba(0,0,0, .15);
    display: flex;
    flex-direction: column;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
   

}

.picture-editor-card--main-picture .picture-editor-card__header{
    background: #808000;
}

.picture-editor-card__info{

}

.picture-editor-card__info h5{

}

.picture-editor-card__info p{  
    color: #555;
}

.picture-editor-card__header{
    background: #333;
    padding: 8px 10px;
    position: relative;
    height: 38px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px
}

.picture-editor-card--movable{
    cursor: pointer;
}

.picture-editor-card__body{
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    /* user-select: none;*/
}

.picture-editor-card__delete-btn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all .2s;
    padding: 3px;
    border-radius: 3px;
}

.picture-editor-card__delete-btn:hover{
    background-color: #666;
}


.picture-editor-card__img-loading{
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    min-height: 200px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.picture-editor-card__img{
    /* user-select: none; */
}

.picture-editor-card__body-main{
    flex: 1;
}

@media only screen and (max-width: 1200px) {
    .picture-editor-card__wrapper{
        flex: 0 0 33.3%;
        max-width: 33.3%;
    }
    .picture-editor-card .btn-primary{
        font-size: 12px;
    }

    .picture-editor__wrapper{
        padding: 20px
    }
}

@media only screen and (max-width: 750px) {
    .picture-editor-card__wrapper{
        min-height: 200px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .picture-editor-card .btn-primary{
       font-size: 11px;
    }

    .picture-editor__wrapper{
        max-height: 500px;
        padding: 30px
    }
} 

@media only screen and (max-width: 500px) {
    .picture-editor-card__wrapper{
        min-height: 200px;
        flex: 0 0 50%;
    }

    .picture-editor-card .btn-primary{
       font-size: 10px;
    }
} 

@media only screen and (max-width: 450px) {
    .picture-editor-card__wrapper{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .picture-editor-card .btn-primary{
       font-size: 10px;
    }
} 


/*
    +++++++++++++++++++++++++++
    EDIT PICTURE OVERLAY
    +++++++++++++++++++++++++++     
*/

.edit-picture-overlay{
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1000;
}

.edit-picture-overlay > * {
    width: 90%;
    max-width: 600px;
}

.edit-picture-overlay--active{
    display: flex;
}

.edit-picture-overlay button{
    margin-left: 1rem;
}

.edit-picture-overlay .edit-picture-overlay__heading{
    padding: 10px;
    color: #fff;
}

.edit-picture-overlay__heading h4{
    color: #fff;
}

.edit-picture-overlay__heading button{
    color: #000;
}


.ellipsis {
    overflow: hidden;
    height: 60px;
    line-height: 20px;
    padding-bottom: 10px;
}

.ellipsis--xl{
    height: 100px;   
}

.ellipsis:before {
    content: "";
    float: left;
    width: 5px;
    height: 200px;
}
.ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px;
}
.ellipsis:after {
    content: "\02026";
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    float: right;
    position: relative;
    top: -25px;
    left: 100%;
    width: 3em;
    margin-left: -3em;
    padding-right: 5px;
    text-align: right;
    background-size: 100% 100%;/* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
    background: -webkit-gradient(linear,left top,right top,
        from(rgba(255,255,255,0)),to(white),color-stop(50%,white));
        background: -moz-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: -o-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: -ms-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
}

@media only screen and (max-width: 750px) {
    .ellipsis {
        overflow: hidden;
        height: 40px;
        line-height: 20px;
        padding-bottom: 10px;
    }
    
    .ellipsis--xl{
        height: 60px;   
    }
    
} 