.delete-dialog{
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1000;
    
}

.delete-dialog button{
    margin-right: 1rem;
}

.delete-dialog--active{
    display: flex;
}

.delete-dialog__modal{
    border-radius: 1rem;
    background-color: #fff;
    padding: 2rem;
}